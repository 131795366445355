import { message } from 'ant-design-vue';
import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
    is_loading: false,
    token: window.localStorage.getItem('token'),
    user_name: window.localStorage.getItem('user_name'),
    nick_name: window.localStorage.getItem('nick_name'),
    user_id: window.localStorage.getItem('user_id'),
    role: window.localStorage.getItem('role'),
};

// getters
const getters = {
    get_if_loading(state) {
        return state.is_loading;
    },
    get_app_msg(state) {
        return state.msg_opts;
    },
    get_token(state) {
        return state.token;
    },
    get_user_name(state) {
        return state.user_name;
    },
    get_nick_name(state) {
        return state.nick_name;
    },
    get_role(state) {
        return state.role;
    },
    get_user_id(state) {
        return state.user_id;
    },
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, sid) {
    //     let aj_host = rootGetters.serverRoot;
    //     fetch(aj_host + '/weapp/takeout/store/get?sid=' + (state.sid || sid), {
    //         method: 'GET'
    //     })
    //         .then((res) => {
    //             return res.text();
    //         })
    //         .then((res) => {
    //             res = JSON.parse(res);
    //         });
    },
    loginAj({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/login", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    addUser({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/user/add", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                        message.success('添加成功');
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    delUser({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/user/del", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                        message.success('删除成功');
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    editUser({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/user/edit", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                        // message.success('更新成功');
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    editUserPswd({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/user/pswd/edit", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                        message.success('更新成功');
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    getUserList({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/user/list", {
                    params: post_data
                })
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    getUserInfo({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/user/info", {
                    params: post_data
                })
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    getPatientList({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/patient/list", {
                    params: post_data
                })
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    downloadPatientFile({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/patient/download", {
                    params: post_data,
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res.data);
                    let uploadExcel = fileName => {
                        const blob = new Blob([res.data], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };
                    uploadExcel("test.xlsx");
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    addData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/patient/add", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        message.success('添加成功');
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    editData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/patient/edit", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        message.success('更新成功');
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    delData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/patient/del", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        message.success('删除成功');
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    getRandomList({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/patient/random", {
                    params: post_data
                })
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        message.warning(res.data.data.msg);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    downloadRandomPatientFile({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + "/weapp/patient/down_random", {
                    params: post_data,
                    responseType: 'blob'
                })
                .then(res => {
                    let uploadExcel = fileName => {
                        const blob = new Blob([res.data], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };
                    uploadExcel("random_group_data.xlsx");
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_MSG(state, _data) {
        state.msg_opts = _data;
    },
    APP_SET_USER_DATA(state, _data) {
        state.token = _data.token;
        state.user_name = _data.user_name;
        state.nick_name = _data.nick_name;
        state.user_id = _data.user_id;
        state.role = _data.role;

        window.localStorage.setItem('token', _data.token);
        window.localStorage.setItem('user_name', _data.user_name);
        window.localStorage.setItem('nick_name', _data.nick_name);
        window.localStorage.setItem('user_id', _data.user_id);
        window.localStorage.setItem('role', _data.role);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
