<template>
    <div>
        <a-page-header
            :ghost="false"
            title="用户信息"
            sub-title="若不修改密码，可无需输入"
        >
        </a-page-header>
        <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <a-form-model-item ref="name" label="登录用户名" prop="user_name">
                <a-input v-model="form.user_name" />
            </a-form-model-item>
            <a-form-model-item ref="nick_name" label="昵称" prop="nick_name">
                <a-input v-model="form.nick_name" />
            </a-form-model-item>
            <a-form-model-item ref="pswd" label="密码" prop="pswd">
                <a-input type="password" v-model="form.pswd" placeholder="请输入密码" />
            </a-form-model-item>
            <a-form-model-item ref="pswd2" label="确认密码" prop="pswd2">
                <a-input type="password" v-model="form.pswd2" placeholder="请再次输入密码"/>
            </a-form-model-item>
            <a-form-model-item  ref="role" label="角色" prop="role">
                <a-input :disabled="true" v-model="form.role" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
                <a-button type="primary" @click="onSubmit">
                    保存
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
    import Vue from 'vue';
    import md5 from 'md5';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'user',
        components: {
        },
        data() {
            return {
                user_info: {},
                labelCol: { span: 8 },
                wrapperCol: { span: 6 },
                other: '',
                form: {
                    user_name: '',
                    nick_name: '',
                    pswd: '',
                    pswd2: '',
                    role: ''
                },
                rules: {
                    user_name: [
                        { required: true, message: '请输入登录用户名', trigger: 'blur' },
                    ],
                    nick_name: [{ required: false }]
                },
                token: this.$store.state.app.token,
            };
        },
        beforeMount() {
        },
        created() {
            this.doGetUserInfo();    
        },
        watch: {},
        mounted() {
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            async doGetUserInfo() {
                let res = await this.getUserInfo();

                if (res && res.code == 100000) {
                    this.user_info = res.data.info;

                    this.$nextTick(() => {
                        this.$set(this.form, 'user_name', this.user_info.user_name);
                        this.$set(this.form, 'nick_name', this.user_info.nick_name);
                        this.$set(this.form, 'role', this.user_info.role);
                    });
                }
            },
            onSubmit(e) {
                e.preventDefault();
                this.$refs['form'].validate(async valid => {
                    if (valid) {
                        let { user_name, nick_name, pswd, pswd2, role } = this.form;

                        if (pswd == '' && pswd2 == '') {
                            let res = await this.editUser({
                                user_name,
                                nick_name,
                                role,
                                user_id: this.user_info.user_id
                            });

                            if (res && res.code == 100000) {
                                this.$message.success('更新成功');
                                this.set_data({
                                    user_name,
                                    nick_name,
                                    role,
                                    token: this.token,
                                    user_id: this.user_info.user_id
                                });
                            }
                        } else {
                            if (pswd == '' || pswd2 == '') {
                                this.$message.warning('2次输入的密码不相符');
                                return;
                            }

                            if (pswd != pswd2) {
                                this.$message.warning('2次输入的密码不相符');
                                return;
                            }

                            let res_data = await this.editUser({
                                user_name,
                                nick_name,
                                role,
                                user_id: this.user_info.user_id
                            });

                            this.set_data({
                                user_name,
                                nick_name,
                                role,
                                token: this.token,
                                user_id: this.user_info.user_id
                            });

                            let res_pswd = await this.editUserPswd({
                                pswd: md5(pswd),
                                user_id: this.user_info.user_id
                            });
                            
                            this.getUserInfo();
                        }
                        return;
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            ...mapActions({
                getUserInfo: 'app/getUserInfo',
                editUser: 'app/editUser',
                editUserPswd: 'app/editUserPswd'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                set_data: 'app/APP_SET_USER_DATA'
            }),
        }
    };
</script>

<style lang="scss">
</style>
