<template>
    <div>
        <a-page-header
            :ghost="false"
            title="随机分组"
            sub-title=""
            >
            <template slot="extra">
                <a-button key="5" :disabled="done == 0 || random_id == ''" type="primary" @click="do_export">
                    全部导出
                </a-button>
            </template>
            <div class="search_content">
                <a-form-model layout="inline" :model="search_form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-model-item label="年龄范围" style="width: 100%;">
                                <a-input style="width: 10%" v-model="search_form.age_s" placeholder="" />
                                <span>&nbsp;-&nbsp;</span>
                                <a-input style="width: 10%" v-model="search_form.age_e" placeholder="" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="入院日期" style="width: 100%;">
                                <a-date-picker
                                    v-model="search_form.date_s"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <a-date-picker
                                    v-model="search_form.date_e"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="每组人数" style="width: 100%;">
                                <a-input type="number" style="width: 30%" v-model="search_form.group_count" placeholder="" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
                <div
                    :style="{
                        width: '100%',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                        zIndex: 1,
                    }"
                >
                    <a-button :style="{ marginRight: '8px' }" @click="do_reset">
                        重置
                    </a-button>
                    <a-button type="primary" @click="do_search">
                        开始分组
                    </a-button>
                </div>
            </div>
        </a-page-header>
        <div style="padding: 20px;">
            <a-tabs v-if="done == 1" v-model="target_group_index" @change="callback">
                <a-tab-pane :tab="'分组' + (index + 1) + '(' + item.length + ')'" v-bind:key="index" v-for="(item, index) in group_data">
                    <a-table rowKey="id" :columns="columns" :data-source="group_data[index]" :pagination="false"></a-table>
                    <div style="width: 100px; margin: 10px auto;">
                        <a-button @click="do_print(group_data[index])">
                            打印
                        </a-button>
                    </div>
                </a-tab-pane>
            </a-tabs>
            <a-result v-if="done == 0" title="暂无数据">
                <template #icon>
                    <a-icon type="frown" />
                </template>
            </a-result>
        </div>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from 'moment';
    import print from 'print-js';

    export default {
        name: 'random',
        components: {},
        data() {
            const columns = [
                {
                    title: '病案号',
                    dataIndex: 'record_id',
                    key: 'record_id',
                    fixed: 'left'
                },
                {
                    title: '姓名',
                    dataIndex: 'user_name',
                    key: 'user_name'
                },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    key: 'gender'
                },
                {
                    title: '年龄',
                    key: 'age',
                    dataIndex: 'age'
                },
                {
                    title: '职业',
                    key: 'job',
                    dataIndex: 'job'
                },
                {
                    title: '婚姻',
                    key: 'marriage',
                    dataIndex: 'marriage'
                },
                {
                    title: '入院日期',
                    key: 'hospitalization_time',
                    dataIndex: 'hospitalization_time',
                    width: 120
                },
                {
                    title: '出院日期',
                    key: 'out_hospitalization_time',
                    dataIndex: 'out_hospitalization_time'
                },
                {
                    title: '受伤原因',
                    key: 'injury_reason',
                    dataIndex: 'injury_reason'
                }
            ];

            return {
                columns,
                done: 0,
                labelCol: { span: 6 },
                wrapperCol: { span: 14 },
                dateFormat: 'YYYY-MM-DD',
                search_form: {
                    age_s: '',
                    age_e: '',
                    date_s: '',
                    date_e: '',
                    group_count: ''
                },
                search_ori_form: {
                    age_s: '',
                    age_e: '',
                    date_s: '',
                    date_e: '',
                    group_count: ''
                },
                group_data: [],
                random_id: '',
                target_group_index: 0
            };
        },
        beforeMount() {},
        created() {},
        watch: {},
        mounted() {},
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            do_search() {
                if (!this.search_form.group_count) {
                    this.$message.warning('请输入每组人数');
                    return;
                }

                if (this.search_form.group_count < 2) {
                    this.$message.warning('每组人数需大于1');
                    return;
                }

                this.initData();
            },
            do_reset() {
                this.search_form = this.search_ori_form;
                this.group_data = [];
                this.done = 0;
                this.target_group_index = 0;
                this.random_id = '';
            },
            async do_export() {
                if (!this.random_id) { return; }
                await this.downloadRandomPatientFile({
                    random_id: this.random_id
                });
            },
            async initData() {
                let search_obj = {};

                search_obj = Object.assign({}, search_obj, this.search_form);
                if (search_obj.date_s) { search_obj.date_s = search_obj.date_s.format('YYYY-MM-DD'); }
                if (search_obj.date_e) { search_obj.date_e = search_obj.date_e.format('YYYY-MM-DD'); }

                let res = await this.getRandomList(search_obj);
                if (res && res.code == 100000) {
                    this.group_data = res.data.groups;
                    this.done = 1;
                    this.random_id = res.data.random_id;
                }
            },
            do_print(data_list) {
                printJS({
                    printable: data_list,
                    properties: [
                        { field: 'record_id', displayName: '病案号'},
                        { field: 'user_name', displayName: '姓名'},
                        { field: 'age', displayName: '年龄'}
                    ],
                    type: 'json'
                });
            },
            callback(key) {
                this.target_group_index = key;
            },
            ...mapActions({
                getRandomList: 'app/getRandomList',
                downloadRandomPatientFile: 'app/downloadRandomPatientFile'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
        }
    };
</script>
