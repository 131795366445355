import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import LoginPage from './views/Login.vue';
import HomePage from './views/Home.vue';
import SystemPage from './views/System.vue';
import RandomPage from './views/Random.vue';
import UserPage from './views/User.vue';
import AnalysisPage from './views/Analysis.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/login', component: LoginPage },
        { path: '/home', component: HomePage },
        { path: '/system', component: SystemPage },
        { path: '/random', component: RandomPage },
        { path: '/user', component: UserPage },
        { path: '/analysis', component: AnalysisPage },
        { path: '*', redirect: '/login' }
    ]
});

router.beforeEach(async (to, from, next) => {
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
