<template>
    <div>
        <a-page-header
            :ghost="false"
            title="数据管理"
            sub-title=""
            >
            <template slot="extra">
                <a-button key="2" v-if="!show_search" @click="toggleSearchZone">
                    展开搜索
                </a-button>
                <a-upload
                    name="file"
                    :headers="headers"
                    :showUploadList="false"
                    :customRequest="upload_request"
                    @change="handleChange"
                >
                    <a-button key="3"> <a-icon type="upload" /> 批量上传 </a-button>
                </a-upload>
                <a-button key="1" type="primary" @click="showAddDrawer">
                    添加数据
                </a-button>
                <a-button key="5" type="primary" @click="do_export">
                    导出
                </a-button>
            </template>
            <div class="search_content" v-if="show_search">
                <a-form-model layout="inline" :model="search_form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row :gutter="24">
                        <a-col :span="6">
                            <a-form-model-item label="病案号" style="width: 100%;">
                                <a-input v-model="search_form.record_id" placeholder="病案号" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="患者姓名" style="width: 100%;">
                                <a-input v-model="search_form.user_name" placeholder="患者姓名" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="性别" style="width: 100%;">
                                <a-select v-model="search_form.gender" placeholder="性别">
                                    <a-select-option value="男">
                                        男
                                    </a-select-option>
                                    <a-select-option value="女">
                                        女
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="职业" style="width: 100%;">
                                <a-input v-model="search_form.job" placeholder="职业" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="年龄范围" style="width: 100%;">
                                <a-input style="width: 45%" v-model="search_form.age_s" placeholder="" />
                                <span>&nbsp;-&nbsp;</span>
                                <a-input style="width: 45%" v-model="search_form.age_e" placeholder="" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="婚姻状况" style="width: 100%;">
                                <a-select v-model="search_form.marriage" placeholder="婚姻状况">
                                    <a-select-option value="已婚">
                                        已婚
                                    </a-select-option>
                                    <a-select-option value="未婚">
                                        未婚
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="受伤原因" style="width: 100%;">
                                <a-select v-model="search_form.injury_reason" placeholder="受伤原因">
                                    <a-select-option value="交通事故"> 交通事故 </a-select-option>
                                    <a-select-option value="高处坠落"> 高处坠落 </a-select-option>
                                    <a-select-option value="低处坠落"> 低处坠落 </a-select-option>
                                    <a-select-option value="体育运动"> 体育运动 </a-select-option>
                                    <a-select-option value="外物砸伤"> 外物砸伤 </a-select-option>
                                    <a-select-option value="assult"> assult </a-select-option>
                                    <a-select-option value="机器伤"> 机器伤 </a-select-option>
                                    <a-select-option value="其他"> 其他 </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model-item :labelCol="{span: 2}" label="入院日期" style="width: 100%;">
                                <a-date-picker
                                    v-model="search_form.date_s"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <a-date-picker
                                    v-model="search_form.date_e"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
                <div
                    :style="{
                        width: '100%',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                        zIndex: 1,
                    }"
                >
                    <a-button :style="{ marginRight: '8px' }" @click="toggleSearchZone">
                        收起(重置)
                    </a-button>
                    <a-button icon="search" type="primary" @click="do_search">
                        搜索
                    </a-button>
                </div>
            </div>
        </a-page-header>
        <a-table rowKey="id" :columns="columns" :data-source="patient_list" :scroll="{ x: 1500, y: 500 }" :pagination="false">
            <span slot="action" slot-scope="text, record">
                <a @click="do_edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="do_del(record)">删除</a>
            </span>
        </a-table>
        <a-pagination
            style="margin-top: 25px"
            @change="turnPage"
            :pageSize="20"
            v-model="cur_page"
            :total="total_count"
            show-less-items
            show-quick-jumper
            :show-total="total => `共 ${total_count} 条数据`"
        />
        <a-drawer
            :title="edit_data && edit_data.id ? '编辑数据' : '添加一条数据'"
            :width="720"
            :visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
            >
            <a-form :form="form" layout="vertical" hide-required-mark>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="病案号">
                            <a-input
                                v-decorator="[
                                'record_id',
                                {
                                    rules: [{ required: true, message: '请输入病例号' }],
                                },
                                ]"
                                placeholder="请输入病例号"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="患者姓名">
                        <a-input
                            v-decorator="[
                            'user_name',
                            {
                                rules: [{ required: true, message: '请输入患者姓名' }],
                            },
                            ]"
                            placeholder="请输入患者姓名"
                        />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="患者年龄">
                        <a-input
                            v-decorator="[
                            'age',
                            {
                                rules: [{ required: true, message: '请输入年龄' }],
                            },
                            ]"
                            placeholder="请输入年龄"
                            type="number"
                        />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="性别">
                            <a-select
                                v-decorator="[
                                'gender',
                                {
                                    rules: [{ required: true, message: '请选择性别' }],
                                },
                                ]"
                                placeholder="请选择性别"
                            >
                                <a-select-option value="男">
                                    男
                                </a-select-option>
                                <a-select-option value="女">
                                    女
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="婚姻状况">
                        <a-select
                            v-decorator="[
                            'marriage',
                            {
                                rules: [{ required: false }],
                            },
                            ]"
                            placeholder="请选择婚姻状态"
                        >
                            <a-select-option value="未婚">
                                未婚
                            </a-select-option>
                            <a-select-option value="已婚">
                                已婚
                            </a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="职业">
                        <a-input
                            v-decorator="[
                                'job',
                                {
                                    rules: [{ required: false }],
                                    initialValue: ''
                                },
                            ]"
                            placeholder="请输入职业"
                        />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="入院日期">
                            <a-date-picker
                                v-decorator="[
                                    'hospitalization_time',
                                    {
                                        rules: [{ required: true, message: '请选择入院日期' }],
                                    },
                                ]"
                                style="width: 100%"
                                :format="dateFormat"
                                :get-popup-container="trigger => trigger.parentNode"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="出院日期">
                            <a-date-picker
                                v-decorator="[
                                    'out_hospitalization_time',
                                    {
                                        rules: [{ required: false }],
                                    },
                                ]"
                                :format="dateFormat"
                                style="width: 100%"
                                :get-popup-container="trigger => trigger.parentNode"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="受伤原因">
                            <a-select
                                v-decorator="[
                                'injury_reason',
                                {
                                    rules: [{ required: true, message: '请选择受伤原因' }],
                                },
                                ]"
                                placeholder="请选择受伤原因"
                            >
                                <a-select-option value="交通事故"> 交通事故 </a-select-option>
                                <a-select-option value="高处坠落"> 高处坠落 </a-select-option>
                                <a-select-option value="低处坠落"> 低处坠落 </a-select-option>
                                <a-select-option value="体育运动"> 体育运动 </a-select-option>
                                <a-select-option value="外物砸伤"> 外物砸伤 </a-select-option>
                                <a-select-option value="assult"> assult </a-select-option>
                                <a-select-option value="机器伤"> 机器伤 </a-select-option>
                                <a-select-option value="其他"> 其他 </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-item label="神经平面">
                            <a-input
                                v-decorator="[
                                'neural_plane',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入神经平面"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="感觉评分">
                            <a-input
                                v-decorator="[
                                'sensory_score',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入感觉评分"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="运动评分">
                        <a-input
                            v-decorator="[
                            'sports_score',
                            {
                                rules: [{ required: false }],
                            },
                            ]"
                            placeholder="请输入运动评分"
                        />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="运动平面">
                        <a-input
                            v-decorator="[
                            'sports_plane',
                            {
                                rules: [{ required: false }],
                            },
                            ]"
                            placeholder="请输入运动平面"
                        />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-item label="是否气切">
                            <a-select
                                v-decorator="[
                                'if_gas_cut',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="是">
                                    是
                                </a-select-option>
                                <a-select-option value="否">
                                    否
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="是否拔管">
                            <a-select
                                v-decorator="[
                                'if_pull_pipe',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="是">
                                    是
                                </a-select-option>
                                <a-select-option value="否">
                                    否
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="戴管时间（天）">
                            <a-input
                                v-decorator="[
                                'with_pipe_days',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="呼吸机使用时间（天）">
                            <a-input
                                v-decorator="[
                                'with_ventilator_days',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="ASIA分级">
                            <a-input
                                v-decorator="[
                                'asia_lv',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入ASIA分级"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="是伴有肺部合并症">
                            <a-select
                                v-decorator="[
                                'with_lungs_disease',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="是">
                                    是
                                </a-select-option>
                                <a-select-option value="否">
                                    否
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="DVT">
                            <a-select
                                v-decorator="[
                                'dvt',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="直立性低血压">
                            <a-select
                                v-decorator="[
                                'orthostatic_hypotension',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="压疮">
                            <a-select
                                v-decorator="[
                                'pressure_ulcer',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="泌尿系感染">
                            <a-select
                                v-decorator="[
                                'urinary_tract',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="受伤后至转入我院时间">
                            <a-input
                                v-decorator="[
                                'injury_to_transfer_time',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="伤后至气管切开时间">
                            <a-input
                                v-decorator="[
                                'injury_to_cut_trachea_time',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="住院康复期间出现肺部疾病">
                            <a-select
                                v-decorator="[
                                'lungs_disease_when_recovery',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="肺部疾病">
                            <a-select
                                v-decorator="[
                                'lungs_disease_type',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肺部感染">
                                    肺部感染
                                </a-select-option>
                                <a-select-option value="肺不张">
                                    肺不张
                                </a-select-option>
                                <a-select-option value="呼吸衰竭">
                                    呼吸衰竭
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="痰培养细菌种类是否耐药菌">
                            <a-select
                                v-decorator="[
                                'if_resistant_bacteria',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="是">
                                    是
                                </a-select-option>
                                <a-select-option value="否">
                                    否
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="痰培养菌群">
                            <a-input
                                v-decorator="[
                                'sputum_culture_flora',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="康复项目1">
                            <a-select
                                v-decorator="[
                                'rehabilitation_program_1',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肢体康复">
                                    肢体康复
                                </a-select-option>
                                <a-select-option value="吞咽训练">
                                    吞咽训练
                                </a-select-option>
                                <a-select-option value="肺康复">
                                    肺康复
                                </a-select-option>
                                <a-select-option value="音乐治疗">
                                    音乐治疗
                                </a-select-option>
                                <a-select-option value="作业治疗">
                                    作业治疗
                                </a-select-option>
                                <a-select-option value="心理治疗">
                                    心理治疗
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="接受康复天数">
                            <a-input
                                v-decorator="[
                                'rehabilitation_days_1',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="康复项目2">
                            <a-select
                                v-decorator="[
                                'rehabilitation_program_2',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肢体康复">
                                    肢体康复
                                </a-select-option>
                                <a-select-option value="吞咽训练">
                                    吞咽训练
                                </a-select-option>
                                <a-select-option value="肺康复">
                                    肺康复
                                </a-select-option>
                                <a-select-option value="音乐治疗">
                                    音乐治疗
                                </a-select-option>
                                <a-select-option value="作业治疗">
                                    作业治疗
                                </a-select-option>
                                <a-select-option value="心理治疗">
                                    心理治疗
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="接受康复天数">
                            <a-input
                                v-decorator="[
                                'rehabilitation_days_2',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="康复项目3">
                            <a-select
                                v-decorator="[
                                'rehabilitation_program_3',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肢体康复">
                                    肢体康复
                                </a-select-option>
                                <a-select-option value="吞咽训练">
                                    吞咽训练
                                </a-select-option>
                                <a-select-option value="肺康复">
                                    肺康复
                                </a-select-option>
                                <a-select-option value="音乐治疗">
                                    音乐治疗
                                </a-select-option>
                                <a-select-option value="作业治疗">
                                    作业治疗
                                </a-select-option>
                                <a-select-option value="心理治疗">
                                    心理治疗
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="接受康复天数">
                            <a-input
                                v-decorator="[
                                'rehabilitation_days_3',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="康复项目4">
                            <a-select
                                v-decorator="[
                                'rehabilitation_program_4',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肢体康复">
                                    肢体康复
                                </a-select-option>
                                <a-select-option value="吞咽训练">
                                    吞咽训练
                                </a-select-option>
                                <a-select-option value="肺康复">
                                    肺康复
                                </a-select-option>
                                <a-select-option value="音乐治疗">
                                    音乐治疗
                                </a-select-option>
                                <a-select-option value="作业治疗">
                                    作业治疗
                                </a-select-option>
                                <a-select-option value="心理治疗">
                                    心理治疗
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="接受康复天数">
                            <a-input
                                v-decorator="[
                                'rehabilitation_days_4',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="康复项目5">
                            <a-select
                                v-decorator="[
                                'rehabilitation_program_5',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="肢体康复">
                                    肢体康复
                                </a-select-option>
                                <a-select-option value="吞咽训练">
                                    吞咽训练
                                </a-select-option>
                                <a-select-option value="肺康复">
                                    肺康复
                                </a-select-option>
                                <a-select-option value="音乐治疗">
                                    音乐治疗
                                </a-select-option>
                                <a-select-option value="作业治疗">
                                    作业治疗
                                </a-select-option>
                                <a-select-option value="心理治疗">
                                    心理治疗
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="接受康复天数">
                            <a-input
                                v-decorator="[
                                'rehabilitation_days_5',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="本次出院后去向">
                            <a-select
                                v-decorator="[
                                'leave_type',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="死亡">
                                    死亡
                                </a-select-option>
                                <a-select-option value="回当地医院">
                                    回当地医院
                                </a-select-option>
                                <a-select-option value="继续我院治疗">
                                    继续我院治疗
                                </a-select-option>
                                <a-select-option value="转其他同级别医院">
                                    转其他同级别医院
                                </a-select-option>
                                <a-select-option value="回家">
                                    回家
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="金属套管戴管时间">
                            <a-input
                                v-decorator="[
                                'with_metal_casing_days',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="塑料套管戴管时间">
                            <a-input
                                v-decorator="[
                                'with_plastic_sleeve_days',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-item label="是否出现气道肉芽、气道狭窄">
                            <a-select
                                v-decorator="[
                                'if_airway_disease',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="是否对气道肉芽行手术干预">
                            <a-select
                                v-decorator="[
                                'if_surgery_airway_disease',
                                {
                                    rules: [{ required: false}],
                                },
                                ]"
                                placeholder="请选择"
                            >
                                <a-select-option value="无">
                                    无
                                </a-select-option>
                                <a-select-option value="有">
                                    有
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-item label="住院总花费">
                            <a-input
                                v-decorator="[
                                'total_costs',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="元"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="康复费用">
                            <a-input
                                v-decorator="[
                                'rehabilitation_costs',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="元"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="住院时间">
                            <a-input
                                v-decorator="[
                                'days_in_hospital',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="随访时间">
                            <a-input
                                v-decorator="[
                                'follow_up_time',
                                {
                                    rules: [{ required: false }],
                                },
                                ]"
                                addon-after="天"
                                placeholder="请输入"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
            >
                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="onSubmit">
                    提交
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from 'moment';
    import axios from 'axios';

    export default {
        name: 'home',
        components: {},
        data() {
            const columns = [
                {
                    title: '病案号',
                    dataIndex: 'record_id',
                    key: 'record_id',
                    fixed: 'left'
                },
                {
                    title: '姓名',
                    dataIndex: 'user_name',
                    key: 'user_name'
                },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    key: 'gender'
                },
                {
                    title: '年龄',
                    key: 'age',
                    dataIndex: 'age'
                },
                {
                    title: '职业',
                    key: 'job',
                    dataIndex: 'job',
                    width: 150
                },
                {
                    title: '婚姻',
                    key: 'marriage',
                    dataIndex: 'marriage'
                },
                {
                    title: '入院日期',
                    key: 'hospitalization_time',
                    dataIndex: 'hospitalization_time',
                    width: 120
                },
                {
                    title: '出院日期',
                    key: 'out_hospitalization_time',
                    dataIndex: 'out_hospitalization_time'
                },
                {
                    title: '受伤原因',
                    key: 'injury_reason',
                    dataIndex: 'injury_reason'
                },
                {
                    title: '神经平面',
                    key: 'neural_plane',
                    dataIndex: 'neural_plane'
                },
                {
                    title: '感觉评分',
                    key: 'sensory_score',
                    dataIndex: 'sensory_score'
                },
                {
                    title: '运动评分',
                    key: 'sports_score',
                    dataIndex: 'sports_score'
                },
                {
                    title: '运动平面',
                    key: 'sports_plane',
                    dataIndex: 'sports_plane'
                },
                {
                    title: 'ASIA分级',
                    key: 'asia_lv',
                    dataIndex: 'asia_lv'
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ];

            return {
                show_search: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 14 },
                columns,
                patient_list: [],
                total_count: 0,
                total_page: 0,
                cur_page: 1,
                form: this.$form.createForm(this),
                visible: false,
                dateFormat: 'YYYY-MM-DD',
                empty_data: {
                    record_id: '',
                    user_name: '',
                    gender: '',
                    age: '',
                    job: '',
                    marriage: '',
                    hospitalization_time: null,
                    out_hospitalization_time: null,
                    injury_reason: '',
                    neural_plane: '',
                    sensory_score: '',
                    sports_score: '',
                    sports_plane: '',
                    asia_lv: '',
                    with_lungs_disease: '',
                    dvt: ''
                },
                edit_data: null,
                search_form: {
                    record_id: '',
                    user_name: '',
                    gender: '',
                    age_s: '',
                    age_e: '',
                    job: '',
                    marriage: '',
                    date_s: '',
                    date_e: '',
                    injury_reason: ''
                },
                search_form_ori: {
                    record_id: '',
                    user_name: '',
                    gender: '',
                    age_s: '',
                    age_e: '',
                    job: '',
                    marriage: '',
                    date_s: '',
                    date_e: '',
                    injury_reason: ''
                },
                debug: this.$store.state.debug,
                token: this.$store.state.app.token,
                headers: {
                    authorization: 'authorization-text',
                },
            };
        },
        beforeMount() {
        },
        async created() {
            this.initData();
        },
        watch: {},
        mounted() {
            
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            doLogin() {
                
            },
            do_edit(record) {
                this.visible = true;
                this.edit_data = record;

                let temp_data = JSON.parse(JSON.stringify(record));
                delete temp_data.id;
                delete temp_data.create_time;
                delete temp_data.update_time;

                this.$nextTick(() => {
                    if (temp_data.out_hospitalization_time == '') {
                        temp_data.out_hospitalization_time = null;
                    } else {
                        temp_data.out_hospitalization_time = moment(temp_data.out_hospitalization_time);
                    }

                    if (temp_data.hospitalization_time == '') {
                        temp_data.hospitalization_time = null;
                    } else {
                        temp_data.hospitalization_time = moment(temp_data.hospitalization_time);
                    }

                    this.form.setFieldsValue(temp_data);
                });
            },
            do_del(record) {
                let that = this;
                this.$confirm({
                    title: '确认删除吗？数据无法恢复！',
                    content: '删除操作为真实删除，无法恢复该条数据，请谨慎操作',
                    cancelText: '取消',
                    okText: '确认',
                    async onOk() {
                        await that.delData({ id: record.id});
                        that.initData();
                    },
                    onCancel() {
                        // console.log('取消');
                    }
                });
            },
            showAddDrawer() {
                this.visible = true;

                this.$nextTick(() => {
                    this.form.setFieldsValue(this.empty_data);
                });
            },
            onClose() {
                this.visible = false;
                this.edit_data = null;
            },
            async onSubmit(e) {
                e.preventDefault();
                this.form.validateFields(async (err, values) => {
                    if (!err) {
                        for (let o in values) {
                            if (values[o] == undefined) {
                                values[o] = '';
                                continue;
                            }
                            
                            if (o == 'hospitalization_time' || o == 'out_hospitalization_time') {
                                if (values[o]) {
                                    values[o] = values[o].format('YYYY-MM-DD');
                                }
                            }
                        }

                        if (this.edit_data && this.edit_data.id) {
                            let post_data = {
                                id: this.edit_data.id,
                                data: JSON.stringify(values)
                            };
                            let res = await this.editData(post_data);
                            if (res && res.code == 100000) {
                                this.onClose();
                                this.initData();
                            }
                        } else {
                            let res = await this.addData(values);
                            if (res && res.code == 100000) {
                                this.onClose();
                                this.initData();
                            }
                        }

                        return;
                    }
                });
            },
            do_search() {
                this.cur_page = 1;
                this.initData();
            },
            async do_export() {
                let search_obj = {};
                search_obj = Object.assign({}, search_obj, this.search_form);
                if (search_obj.date_s) { search_obj.date_s = search_obj.date_s.format('YYYY-MM-DD'); }
                if (search_obj.date_e) { search_obj.date_e = search_obj.date_e.format('YYYY-MM-DD'); }

                await this.downloadPatientFile(search_obj);
            },
            async initData() {
                let search_obj = {
                    page_size: 20,
                    page: this.cur_page
                };

                search_obj = Object.assign({}, search_obj, this.search_form);
                if (search_obj.date_s) { search_obj.date_s = search_obj.date_s.format('YYYY-MM-DD'); }
                if (search_obj.date_e) { search_obj.date_e = search_obj.date_e.format('YYYY-MM-DD'); }

                let res = await this.getPatientList(search_obj);
                if (res && res.code == 100000) {
                    this.patient_list = res.data.list;
                    this.total_page = res.data.total_page;
                    this.total_count = res.data.total_count;
                }
            },
            turnPage(page) {
                this.cur_page = page;
                this.initData();
            },
            toggleSearchZone() {
                if (this.show_search) {
                    this.search_form = this.search_form_ori;
                    this.initData();
                }
                this.show_search = !this.show_search;
            },
            upload_request(info) {
                var formData = new FormData();
                formData.append('file', info.file);

                let size = info.file.size;
                var type = info.file.name.substring(info.file.name.indexOf(".")); 

                if (size > 20 * 1024 * 1024) {
                    this.$message.warning('文件大小不能超过20MB');
                    return;
                }

                if (type != '.csv') {
                    this.$message.warning('只能上传csv格式的文件');
                    return;
                }

                // 限制文件类型
                this.set_loading({ is_loading: true });
                
                let aj_host = this.debug ? "http://127.0.0.1:9090" : "";

                axios.post(aj_host + "/weapp/patient/upload", formData, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((res) => {
                    if (res && res.data && res.data.data.code == 100000) {
                        this.$message.success('成功导入' + res.data.data.data.success_count + '条数据');
                        this.initData();
                    }
                    this.set_loading({ is_loading: false });
                })
                .catch(function (err) {
                    console.log(err);
                    this.set_loading({ is_loading: false });
                });
            },
            handleChange(info) {
                let file = info.fileList[0];
            },
            ...mapActions({
                getPatientList: 'app/getPatientList',
                addData: 'app/addData',
                editData: 'app/editData',
                delData: 'app/delData',
                downloadPatientFile: 'app/downloadPatientFile'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                set_loading: 'app/APP_SET_IF_LOADING'
            }),
        }
    };
</script>
