<template>
    <div>
        <a-page-header
            :ghost="false"
            title="用户管理"
            sub-title=""
            >
            <template slot="extra">
                <a-button key="1" type="primary" @click="showAddDrawer">
                    添加用户
                </a-button>
            </template>
        </a-page-header>
        <a-table rowKey="id" :columns="columns" :data-source="user_list" :pagination="{ pageSize: 20 }">
            <span slot="action" slot-scope="text, record">
                <a @click="do_edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="do_change_pswd(record)">修改密码</a>
                <a-divider type="vertical" />
                <a @click="do_del(record)">删除</a>
            </span>
        </a-table>
        <a-drawer
            :title="drawer_type != 'add' ? '编辑数据' : '添加用户'"
            :width="540"
            :visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
            >
            <a-form :form="form" layout="vertical" hide-required-mark>
                <a-row :gutter="16">
                    <a-col :span="24" v-if="drawer_type != 'change_pswd'">
                        <a-form-item label="登录用户名">
                            <a-input
                                v-decorator="[
                                'user_name',
                                {
                                    rules: [{ required: true, message: '请输入用户名' }],
                                },
                                ]"
                                placeholder="请输入用户名"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24" v-if="drawer_type != 'change_pswd'">
                        <a-form-item label="昵称">
                        <a-input
                            v-decorator="[
                            'nick_name',
                            {
                                rules: [{ required: true, message: '请输入昵称' }],
                            },
                            ]"
                            placeholder="请输入昵称"
                        />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24"  v-if="drawer_type != 'edit'">
                        <a-form-item label="登录密码">
                        <a-input
                            v-decorator="[
                            'pswd',
                            {
                                rules: [{ required: true, message: '请输入密码' }],
                            },
                            ]"
                            placeholder="请输入密码"
                            type="password"
                        />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24"  v-if="drawer_type != 'edit'">
                        <a-form-item label="确认登录密码">
                        <a-input
                            v-decorator="[
                            'pswd2',
                            {
                                rules: [{ required: true, message: '请再次输入密码' }],
                            },
                            ]"
                            placeholder="请再次输入密码"
                            type="password"
                        />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24" v-if="drawer_type != 'change_pswd'">
                        <a-form-item label="角色">
                            <a-select
                                v-decorator="[
                                'role',
                                {
                                    rules: [{ required: true, message: '请选择角色' }],
                                },
                                ]"
                                placeholder="请选择角色"
                            >
                                <a-select-option value="admin">
                                    管理员
                                </a-select-option>
                                <a-select-option value="user">
                                    普通用户
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
            >
                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="onSubmit">
                    提交
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from 'moment';
    import md5 from 'md5';

    export default {
        name: 'home',
        components: {},
        data() {
            const columns = [
                {
                    title: '用户id',
                    dataIndex: 'user_id',
                    key: 'user_id'
                },
                {
                    title: '用户名',
                    dataIndex: 'user_name',
                    key: 'user_name'
                },
                {
                    title: '昵称',
                    dataIndex: 'nick_name',
                    key: 'nick_name'
                },
                {
                    title: '角色',
                    key: 'role',
                    dataIndex: 'role'
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ];

            return {
                columns,
                user_list: [],
                total_count: 0,
                form: this.$form.createForm(this),
                visible: false,
                dateFormat: 'YYYY-MM-DD',
                empty_data: {
                    user_name: '',
                    role: 'user',
                    nick_name: '',
                    pswd: '',
                    pswd2: ''
                },
                edit_data: null,
                drawer_type: 'add'
            };
        },
        beforeMount() {
        },
        async created() {
            this.initData();
        },
        watch: {},
        mounted() {
            
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            do_change_pswd(record) {
                this.visible = true;
                this.drawer_type = 'change_pswd';
                this.edit_data = record;

                this.$nextTick(() => {
                    this.form.setFieldsValue({
                        pswd: '',
                        pswd2: ''
                    });
                });
            },
            do_edit(record) {
                this.visible = true;
                this.drawer_type = 'edit';
                this.edit_data = record;

                let temp_data = JSON.parse(JSON.stringify(record));
                delete temp_data.id;
                delete temp_data.user_id;
                delete temp_data.pswd;
                delete temp_data.create_time;
                delete temp_data.update_time;

                this.$nextTick(() => {
                    this.form.setFieldsValue(temp_data);
                });
            },
            do_del(record) {
                let that = this;
                this.$confirm({
                    title: '确认删除吗？数据无法恢复！',
                    content: '删除操作为真实删除，无法恢复该条数据，请谨慎操作',
                    cancelText: '取消',
                    okText: '确认',
                    async onOk() {
                        await that.delData({ id: record.id});
                        that.initData();
                    },
                    onCancel() {
                        // console.log('取消');
                    }
                });
            },
            showAddDrawer() {
                this.visible = true;
                this.drawer_type = 'add';

                this.$nextTick(() => {
                    this.form.setFieldsValue(this.empty_data);
                });
            },
            onClose() {
                this.visible = false;
                this.edit_data = null;
                this.drawer_type = '';
            },
            async onSubmit(e) {
                e.preventDefault();
                this.form.validateFields(async (err, values) => {
                    if (!err) {
                        for (let o in values) {
                            if (values[o] == undefined) {
                                values[o] = '';
                                continue;
                            }
                        }

                        if (this.edit_data && this.edit_data.id) {
                            if (this.drawer_type == 'edit') {
                                let { user_name, nick_name, role } = values;

                                let res = await this.editUser({
                                    user_name,
                                    nick_name,
                                    role,
                                    user_id: this.edit_data.user_id
                                });

                                if (res && res.code == 100000) {
                                    this.onClose();
                                    this.initData();
                                    this.$message.success('更新成功');
                                }
                            } else if  (this.drawer_type == 'change_pswd') {
                                let { pswd, pswd2 } = values;

                                if (pswd != pswd2) {
                                    this.$message.warning('2次输入的密码不相符');
                                    return;
                                }

                                let res = await this.editUserPswd({
                                    pswd: md5(pswd),
                                    user_id: this.edit_data.user_id
                                });

                                if (res && res.code == 100000) {
                                    this.onClose();
                                    this.initData();
                                }
                            }
                            
                        } else {
                            // let res = await this.addUser(values);
                            let { user_name, nick_name, pswd, pswd2, role } = values;

                            if (pswd != pswd2) {
                                this.$message.warning('2次输入的密码不相符');
                                return;
                            }

                            let res = await this.addUser({
                                user_name,
                                nick_name,
                                pswd: md5(pswd),
                                role
                            });

                            if (res && res.code == 100000) {
                                this.onClose();
                                this.initData();
                            }
                        }

                        return;
                    }
                });
            },
            async initData() {
                let res = await this.getUserList();
                if (res && res.code == 100000) {
                    this.user_list = res.data.list;
                    this.total_count = this.user_list.length;
                }
            },
            turnPage(page) {
                this.cur_page = page;
                this.initData();
            },
            ...mapActions({
                getUserList: 'app/getUserList',
                editUser: 'app/editUser',
                addUser: 'app/addUser',
                delUser: 'app/delUser',
                editUserPswd: 'app/editUserPswd'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
        }
    };
</script>
