require('es6-promise').polyfill(); //es6 promise
require('isomorphic-fetch'); //fetch

import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import router from './router';
import antDesign from "ant-design-vue";
import 'ant-design-vue/dist/antd.css'
import VueLazyload from 'vue-lazyload';
import { message } from "ant-design-vue";
import _ from "lodash";

Vue.prototype.$message = message;
Vue.prototype._ = _;
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
});
Vue.use(antDesign)

export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        render: (h) => h(App)
    });

    return { app, router };
}

window.onload = () => createApp();
