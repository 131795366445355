<template>
    <div>
        <a-page-header
            :ghost="false"
            title="数据分析"
            sub-title=""
            >
            <template slot="extra">
                <!-- <a-button key="1">
                    导出
                </a-button> -->
            </template>
            <div class="search_content">
                <a-form-model layout="inline" :model="search_form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row :gutter="24">
                        <a-col :span="8">
                            <a-form-model-item label="年龄范围" style="width: 100%;">
                                <a-input-number style="width: 30%" v-model="search_form.age_s" placeholder="" />
                                <span>&nbsp;-&nbsp;</span>
                                <a-input-number style="width: 30%" v-model="search_form.age_e" placeholder="" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model-item label="入院日期" style="width: 100%;">
                                <a-date-picker
                                    v-model="search_form.date_s"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <a-date-picker
                                    v-model="search_form.date_e"
                                    :format="dateFormat"
                                    :get-popup-container="trigger => trigger.parentNode"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item :labelCol="{ 'span': 2}" :wrapperCol="{'span': 20}" label="选择指标" style="width: 100%;">
                                <a-select mode="multiple" size="default" :default-value="[]" style="width: 100%;" @change="handleSelectChange">
                                    <a-select-option v-for="(item, index) in key_list" :key="index" :value="item.val">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
                <div
                    :style="{
                        width: '100%',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                        zIndex: 1,
                    }"
                >
                    <a-button type="primary" @click="do_search">
                        分析
                    </a-button>
                </div>
            </div>
        </a-page-header>
    </div>
</template>

<script>
    import Vue from 'vue';
    import md5 from 'md5';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'anaylsis',
        components: {
        },
        data() {
            return {
                labelCol: { span: 6 },
                wrapperCol: { span: 14 },
                dateFormat: 'YYYY-MM-DD',
                search_form: {
                    age_s: '',
                    age_e: '',
                    date_s: '',
                    date_e: '',
                },
                search_ori_form: {
                    age_s: '',
                    age_e: '',
                    date_s: '',
                    date_e: '',
                },
                done: 0,
                key_list: [
                    { name: '性别', val: 'gender'},
                    { name: '年龄', val: 'age'},
                    { name: '婚姻', val: 'marriage'},
                    { name: '受伤原因', val: 'injury_reason' },
                    { name: '神经平面', val: 'neural_plane' },
                ],
                select_keys: []
            };
        },
        beforeMount() {
        },
        created() {
        },
        watch: {},
        mounted() {
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            do_search() {
                this.initData();
            },
            do_reset() {
                this.search_form = this.search_ori_form;
                this.done = 0;
            },
            async initData() {
                let search_obj = {};

                search_obj = Object.assign({}, search_obj, this.search_form);
                if (search_obj.date_s) { search_obj.date_s = search_obj.date_s.format('YYYY-MM-DD'); }
                if (search_obj.date_e) { search_obj.date_e = search_obj.date_e.format('YYYY-MM-DD'); }

                search_obj.keys = this.select_keys;

                console.log(search_obj);
            },
            handleSelectChange(value) {
                console.log(`Selected: ${value}`);
                this.select_keys = value;
            },
            ...mapActions({
                getRandomList: 'app/getRandomList'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
        }
    };
</script>

<style lang="scss">
</style>
