<template lang="html">
    <div id="app" style="height: 100vh;">
        <div v-if="is_login">
            <router-view></router-view>
        </div>
        <div v-else style="height: 100%;">
            <a-layout style="height: 100%;">
                <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
                    <div class="logo">
                        <span v-if="!collapsed">中国康复研究中心</span>
                        <span v-else>研究</span>
                    </div>
                    <LeftNav />
                </a-layout-sider>
                <a-layout>
                    <a-layout-header style="background: #fff; padding: 0">
                        <a-icon
                            class="trigger"
                            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                            @click="() => (collapsed = !collapsed)"
                        />
                        <div class="user_zone">
                            <span> 欢迎您 {{ nick_name || user_name }} </span>
                            <a-dropdown>
                                <span class="opearate" @click="e => e.preventDefault()">
                                    <a-icon class="user_logo" type="user" />
                                    <a-icon class="toggle_zone" type="down" />
                                </span>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a href="javascript:;" @click="go_to_page">查看资料</a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a href="javascript:;" @click="do_logout">退出登录</a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </a-layout-header>
                    <a-layout-content
                        :style="{ margin: '12px 12px', padding: '0px 10px', background: '#fff', minHeight: '280px' }"
                    >
                        <a-spin :spinning="spinning">
                            <router-view></router-view>
                        </a-spin>
                    </a-layout-content>
                </a-layout>
            </a-layout>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    import LeftNav from '../components/LeftNav';
    Vue.component(LeftNav.name, LeftNav);

    export default {
        name: 'app',
        components: {
        },
        data() {
            return {
                collapsed: false,
                selectedKeys: ['1'],
                is_login: this.$router.history.current.path.indexOf('/login') > -1 ? true : false,
            };
        },
        beforeMount() {
            this.is_login = this.$router.history.current.path.indexOf('/login') > -1 ? true : false;
            let _token = window.localStorage.getItem('token') || '';

            if (!this.is_login && !_token) {
                this.$router.replace({ path: "login" });
            }
        },
        updated() {},
        beforeCreate() {},
        created() {
        },
        watch: {
            '$route'() {
                this.is_login = this.$router.history.current.path.indexOf('/login') > -1 ? true : false;
            }
        },
        computed: {
            ...mapGetters({
                msg_opts: 'app/get_app_msg',
                nick_name: 'app/get_nick_name',
                user_name: 'app/get_user_name',
                spinning: 'app/get_if_loading'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                initData: 'app/initData',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            do_logout() {
                let that = this;
                this.$confirm({
                    title: '确认退出吗？',
                    cancelText: '取消',
                    okText: '确认',
                    async onOk() {
                        window.localStorage.removeItem('token');
                        window.localStorage.removeItem('user_name');
                        window.localStorage.removeItem('nick_name');
                        window.localStorage.removeItem('user_id');
                        window.localStorage.removeItem('role');

                        that.$router.replace({ path: "/login" });
                    },
                    onCancel() {
                    }
                });
            },
            go_to_page() {
                this.$router.push({ path: "/user" });
            }
        }
    };
</script>

<style>
    #app .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #app .trigger:hover {
        color: #1890ff;
    }

    #app .logo {
        height: 32px;
        margin: 16px;
        color: #fff;
        font-size: 20px;
    }

    #app .user_zone {
        float: right;
    }

    #app .user_zone .user_logo {
        font-size: 18px;
        line-height: 64px;
        padding-left: 24px;
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;
        transition: color 0.3s;
    }

    #app .user_zone .toggle_zone {
        font-size: 10px;
        line-height: 64px;
        padding-right: 24px;
        cursor: pointer;
        transition: color 0.3s;
    }
    #app .opearate:hover {
        color: #1890ff;
    }
</style>
