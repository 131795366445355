var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-page-header',{attrs:{"ghost":false,"title":"用户管理","sub-title":""}},[_c('template',{slot:"extra"},[_c('a-button',{key:"1",attrs:{"type":"primary"},on:{"click":_vm.showAddDrawer}},[_vm._v(" 添加用户 ")])],1)],2),_c('a-table',{attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.user_list,"pagination":{ pageSize: 20 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.do_edit(record)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.do_change_pswd(record)}}},[_vm._v("修改密码")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.do_del(record)}}},[_vm._v("删除")])],1)}}])}),_c('a-drawer',{attrs:{"title":_vm.drawer_type != 'add' ? '编辑数据' : '添加用户',"width":540,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-row',{attrs:{"gutter":16}},[(_vm.drawer_type != 'change_pswd')?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"登录用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'user_name',
                            {
                                rules: [{ required: true, message: '请输入用户名' }],
                            },
                            ]),expression:"[\n                            'user_name',\n                            {\n                                rules: [{ required: true, message: '请输入用户名' }],\n                            },\n                            ]"}],attrs:{"placeholder":"请输入用户名"}})],1)],1):_vm._e(),(_vm.drawer_type != 'change_pswd')?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'nick_name',
                        {
                            rules: [{ required: true, message: '请输入昵称' }],
                        },
                        ]),expression:"[\n                        'nick_name',\n                        {\n                            rules: [{ required: true, message: '请输入昵称' }],\n                        },\n                        ]"}],attrs:{"placeholder":"请输入昵称"}})],1)],1):_vm._e(),(_vm.drawer_type != 'edit')?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'pswd',
                        {
                            rules: [{ required: true, message: '请输入密码' }],
                        },
                        ]),expression:"[\n                        'pswd',\n                        {\n                            rules: [{ required: true, message: '请输入密码' }],\n                        },\n                        ]"}],attrs:{"placeholder":"请输入密码","type":"password"}})],1)],1):_vm._e(),(_vm.drawer_type != 'edit')?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"确认登录密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'pswd2',
                        {
                            rules: [{ required: true, message: '请再次输入密码' }],
                        },
                        ]),expression:"[\n                        'pswd2',\n                        {\n                            rules: [{ required: true, message: '请再次输入密码' }],\n                        },\n                        ]"}],attrs:{"placeholder":"请再次输入密码","type":"password"}})],1)],1):_vm._e(),(_vm.drawer_type != 'change_pswd')?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'role',
                            {
                                rules: [{ required: true, message: '请选择角色' }],
                            },
                            ]),expression:"[\n                            'role',\n                            {\n                                rules: [{ required: true, message: '请选择角色' }],\n                            },\n                            ]"}],attrs:{"placeholder":"请选择角色"}},[_c('a-select-option',{attrs:{"value":"admin"}},[_vm._v(" 管理员 ")]),_c('a-select-option',{attrs:{"value":"user"}},[_vm._v(" 普通用户 ")])],1)],1)],1):_vm._e()],1)],1),_c('div',{style:({
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
            })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }