<template>
    <a-menu theme="dark" mode="inline" v-model="now_index">
        <a-menu-item key="1" @click="choose_page(1)">
            <a-icon type="appstore" />
            <span>数据管理</span>
        </a-menu-item>
        <a-menu-item key="2" @click="choose_page(2)">
            <a-icon type="dashboard" />
            <span>数据分析</span>
        </a-menu-item>
        <a-menu-item key="3" @click="choose_page(3)">
            <a-icon type="apartment" />
            <span>随机分组</span>
        </a-menu-item>
        <a-menu-item key="4" @click="choose_page(4)" v-if="is_super">
            <a-icon type="setting" />
            <span>系统管理</span>
        </a-menu-item>
    </a-menu>
</template>
<style></style>
<script>
import Vue from "vue";

export default {
    name: "LeftNav",
    data() {
        return {
            current_url: "",
            now_index: ['1'],
            role: this.$store.state.app.role,
            url_index: {
                '/home': ['1'],
                '/analysis': ['2'],
                '/random': ['3'],
                '/system': ['4'],
            }
        };
    },
    mounted() {
        this.current_url = this.$router.history.current.path;
        this.now_index = this.url_index[this.current_url] || [];
    },
    watch: {
        $route() {
            this.current_url = this.$router.history.current.path;
            this.now_index = this.url_index[this.current_url] || [];
        },
    },
    methods: {
        choose_page(index) {
            if (index == this.now_index) {
                return;
            }

            this.now_index = [index];
            let _arr = ['', '/home', '/analysis', '/random', '/system'];
            this.$router.push({ path: _arr[index] });
        }
    },
    computed: {
        is_super: function() {
            return this.$store.state.app.role === "admin";
        },
    },
};
</script>
