<template>
    <div class="login-page">
        <div class="wallpaper-register"></div>
        <div class="container">
            <a-row justify="center" align="top">
                <a-col :span="24">
                    <a-card style="width: 100%;">
                        <h1 style="text-align: center;">登录</h1>
                        <a-space direction="vertical" style="width: 100%;">
                            <a-input size="large" v-model="user_name" placeholder="用户名" />
                            <a-input size="large" v-model="pswd" type="password" placeholder="密码" />
                            <a-row>
                                <a-col :span="9"></a-col>
                                <a-col :span="6">
                                    <a-button size="large" type="primary" @click="doLogin">提交</a-button>
                                </a-col>
                            </a-row>
                        </a-space>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import md5 from 'md5';
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'login',
        components: {
        },
        data() {
            return {
                user_name: "",
                pswd: ""
            };
        },
        beforeMount() {
            let _token = window.localStorage.getItem('token') || '';

            if (_token) {
                this.$router.replace({ path: "/home" });
            }
        },
        async created() {
        },
        watch: {},
        mounted() {
            
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            async doLogin() {
                if (!this.user_name || !this.pswd) {
                    return;
                }

                let res = await this.loginAj({
                    user_name: this.user_name,
                    pswd: md5(this.pswd)
                });

                if (res && res.code == 100000) {
                    this.set_data(res.data);
                    this.$router.replace({ path: "/home" });
                }
            },
            ...mapActions({
                loginAj: 'app/loginAj'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                set_data: 'app/APP_SET_USER_DATA'
            }),
        }
    };
</script>

<style lang="scss">
    p {
        line-height: 1rem;
    }

    .card {
        padding: 20px;
    }

    .form-group {
        input {
            margin-bottom: 20px;
        }
    }

    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
    
        .wallpaper-register {
            background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
                no-repeat center center;
            background-size: cover;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        h1 {
            margin-bottom: 1.5rem;
        }
    }

    .error {
        animation-name: errorShake;
        animation-duration: 0.3s;
    }

    @keyframes errorShake {
        0% {
            transform: translateX(-25px);
        }
        25% {
            transform: translateX(25px);
        }
        50% {
            transform: translateX(-25px);
        }
        75% {
            transform: translateX(25px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .container {
        width: 400px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 720px;
    }
</style>
